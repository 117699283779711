import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// components
import Logo from 'src/components/logo';
import Scrollbar from 'src/components/scrollbar';
import { usePathname } from 'src/routes/hook';
import { NavSectionVertical } from 'src/components/nav-section';
//
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import { NavToggleButton, NavUpgrade } from '../_common';
import { tradingApi } from 'src/api/trading';
import { Chip, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function NavVertical({ openNav, onCloseNav }) {
  const { user } = useMockedUser();

  const pathname = usePathname();

  const lgUp = useResponsive('up', 'lg');

  const navData = useNavData();

  const [balance, setBalance] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [currentBalance, setCurrentBalance] = useState();

  const { t } = useTranslation();

  const handleGetBalance = async () => {
    try {
      const { data } = await tradingApi.getBalance();

      if (data?.balance) setBalance(data?.balance);
    } catch (error) {
      console.error('error: ', error);
    }
  }

  const handleGetInitialBalance = async () => {
    try {
      setIsLoading(true);
      const { data } = await tradingApi.getBalance();

      if (data?.balance) setBalance(data?.balance);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('error: ', error);
    }
  }

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    handleGetInitialBalance();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      handleGetBalance();
    }, 4000);

    return () => {
      clearInterval(interval);
    }
  }, []);

  const getBalance = () => {
    if (balance) {
      const nonZeroBalanceCount = Object.values(balance)?.filter(b => b?.balance > 0)?.length;
      if (nonZeroBalanceCount === 2) {
        setCurrentBalance(Object.values(balance)?.filter(b => b?.balance > 0)?.[0]);
      } else if (nonZeroBalanceCount > 2) {
        setCurrentBalance(balance?.total)
      }
    }
  }

  useEffect(() => {
    getBalance();
  }, [balance]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Logo sx={{ mt: 3, ml: 4, mb: 1 }} />

      <Stack px={3} py={1} direction="row" alignItems="center" justifyContent="space-around">
        <Stack alignItems="center" spacing={.5}>
          {t("Balance")}:
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <Chip label={currentBalance?.balance ? currentBalance?.balance?.toFixed(2) : "N/A"} variant="outlined" />
          )}
        </Stack>

        <Stack alignItems="center" spacing={.5}>
          {t("Equity")}:
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <Chip label={currentBalance?.equity ? currentBalance?.equity?.toFixed(2) : "N/A"} variant="outlined" />
          )}
        </Stack>
      </Stack>

      <NavSectionVertical
        data={navData}
        config={{
          currentRole: user?.role || 'admin',
        }}
      />

      <Box sx={{ flexGrow: 1 }} />

      <NavUpgrade />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

NavVertical.propTypes = {
  onCloseNav: PropTypes.func,
  openNav: PropTypes.bool,
};
