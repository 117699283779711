import { Navigate, useRoutes, Outlet } from 'react-router-dom';
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { paths } from '../paths';
import { lazy } from 'react';

const Page404 = lazy(() => import('src/pages/404'));

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: <Outlet />,
      children: [{ element: <Navigate to={paths.login} />, index: true }],
    },

    // SET 404 PAGE
    {
      path: '404',
      element: <Page404 />
    },

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
