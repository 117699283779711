import axios from 'src/utils/axios';

class TradingApi {
  async getPositions(params = {}) {
    const response = await axios.get(`/client/client_account/finance/positions`, { params });
    return response;
  }

  async getBalance(params = {}) {
    const response = await axios.get('/client/client_account/finance/balance', { params });
    return response;
  }
}

export const tradingApi = new TradingApi();
