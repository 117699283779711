import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';

// components
import SvgColor from 'src/components/svg-color';
import { useTranslate } from 'src/locales';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  label: icon('ic_label'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  fund: icon('ic_fund'),
  withdraw: icon('ic_withdraw'),
  transaction: icon('ic_transaction'),
  upload: icon('ic_upload'),
  ico: icon('ic_ico'),
  savingAccounts: icon('ic_saving'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useTranslate();

  const data = useMemo(
    () => [
      // ----------------------------------------------------------------------
      {
        subheader: 'OVERVIEW',
        items: [
          { title: t('Dashboard'), path: paths.main.dashboard, icon: ICONS.dashboard },
          {
            title: t("ICO"),
            path: paths.main.ico,
            icon: ICONS.ico,
          },
          {
            title: t("Saving accounts"),
            path: paths.main.savingAccounts,
            icon: ICONS.savingAccounts,
          },
          {
            title: t('Upload Document'),
            path: paths.main.uploadDocument,
            icon: ICONS.upload,
          },
          {
            title: t('Deposit Funds'),
            path: paths.main.depositFund,
            icon: ICONS.fund,
          },
          { title: t('Withdrawal'), path: paths.main.withdrawal, icon: ICONS.withdraw },
          {
            title: t('Monetary Transaction'),
            path: paths.main.monetaryTransaction,
            icon: ICONS.transaction,
          },
          {
            title: t('Trading History'),
            path: paths.main.tradingHistory,
            icon: ICONS.analytics,
          },
          {
            title: t('Trading Terminal'),
            path: paths.main.tradingPortals,
            icon: ICONS.label,
          },
          { title: t('Contact Us'), path: paths.main.contactUs, icon: ICONS.user },
        ],
      },
    ],
    [t]
  );

  return data;
}
