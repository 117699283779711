import axios from 'axios';
import { getBaseApiUrl } from '../config';
// config

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: getBaseApiUrl() });

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `${token}`;
    }

    Object.entries(config.params || {})
      .filter(([_, value]) => !value)
      .forEach(([key]) => {
        delete config.params[key];
      });

    return config;
  },
  (error) => {
    // Handle request errors
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle response errors
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error('Response error:', error.response.data);
      console.error('Status code:', error.response.status);
      if (error?.response?.status === 401) {
        window.location.replace(window.location.origin + '/login');
        localStorage.clear();
        sessionStorage.clear();
        console.error('error 401');
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
    } else {
      // Something happened in setting up the request that triggered an error
      console.error('Error:', error.message);
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
