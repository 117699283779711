// ----------------------------------------------------------------------

export const paths = {
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',

  // AUTH
  login: `/login`,
  register: '/register',
  forgotPass: `/forgot-password`,

  // MAIN
  main: {
    root: '/',
    dashboard: '/dashboard',
    uploadDocument: '/upload-document',
    depositFund: '/deposit-fund',
    withdrawal: '/withdrawal',
    monetaryTransaction: '/monetary-transaction',
    tradingHistory: '/trading-history',
    tradingPortals: '/trading-portals',
    contactUs: '/contact-us',
    account: '/account',
    ico: '/ico',
    savingAccounts: '/saving-accounts',
  },
};
