import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';

// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
// Monetary Transactions
const MonetaryTransactionPage = lazy(() =>
  import('src/pages/dashboard/monetary-transactions/list')
);
// Transaction History
const TransactionHistoryPage = lazy(() => import('src/pages/dashboard/trading-history/list'));
// Trading Portals
const TradingPortals = lazy(() => import('src/pages/dashboard/trading-portals/index'));
// Upload Document
const UploadDocumentPage = lazy(() => import('src/pages/dashboard/upload-document/index'));
// Withdrawal
const WithrawalPage = lazy(() => import('src/pages/dashboard/withdrawal/index'));
// Contact Us
const ContactUsPage = lazy(() => import('src/pages/dashboard/support/index'));
// Deposit Funds
const DepositFundsPage = lazy(() => import('src/pages/dashboard/deposit-funds/index'));
// Account
const AccountPage = lazy(() => import('src/pages/dashboard/account/index'));
// ICO
const IcoPage = lazy(() => import('src/pages/dashboard/ico/index'));
// Saving accounts
const SavingAccountsPage = lazy(() => import('src/pages/dashboard/saving-accounts/index'));
// Terms
const Terms = lazy(() => import('src/pages/terms'));
// Privacy
const PrivacyPolicy = lazy(() => import('src/pages/privacy'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    element: (
      <DashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      { path: 'dashboard', element: <IndexPage /> },
      { path: 'ico', element: <IcoPage /> },
      { path: 'saving-accounts', element: <SavingAccountsPage /> },
      { path: 'upload-document', element: <UploadDocumentPage /> },
      { path: 'deposit-fund', element: <DepositFundsPage /> },
      { path: 'withdrawal', element: <WithrawalPage /> },
      { path: 'monetary-transaction', element: <MonetaryTransactionPage /> },
      { path: 'trading-history', element: <TransactionHistoryPage /> },
      { path: 'trading-portals', element: <TradingPortals /> },
      { path: 'contact-us', element: <ContactUsPage /> },
      { path: 'account', element: <AccountPage /> },
      { path: 'terms', element: <Terms /> },
      { path: 'privacy-policy', element: <PrivacyPolicy /> },
    ],
  },
];
