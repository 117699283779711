import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
import { useAuthContext } from 'src/auth/hooks';
import { useRouter } from 'src/routes/hook';
import { useTranslate } from 'src/locales';

export default function NavUpgrade() {
  const { user, logout } = useAuthContext();
  const router = useRouter();

  const handleLogout = async () => {
    try {
      await logout();
      router.replace(paths.login);
    } catch (error) {
      console.error(error);
    }
  };
  
  const {t} = useTranslate();

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Box sx={{ position: 'relative' }}>
          <Avatar src={user?.avatar} alt="avatar" sx={{ width: 48, height: 48 }} />
        </Box>

        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Link
            variant="subtitle2"
            noWrap
            color="inherit"
            component={RouterLink}
            href={`${paths.main.account}`}
          >
            {user?.full_name}
          </Link>

          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            {user?.email}
          </Typography>
        </Stack>
        <Button onClick={() => handleLogout()} variant="contained">
          {t("Logout")}
        </Button>
      </Stack>
      <Link
        component={RouterLink}
        href={`${paths.main.account}?tab=security`}
        variant="subtitle2"
        color="primary"
        sx={{ pt: 2 }}
      >
        {t("Change Password")}
      </Link>
    </Stack>
  );
}
